<template>
  <div class="container login">
    <div class="login__back">
      <div
        v-if="forgotPassword !== '' || verificationState === 'completed'"
        @click="goBack"
      >
        <img
          class="img-fluid mx-1"
          width="25px"
          src="@/assets/static-assets/icons/Arrow-left.svg"
          alt="back"
        />
        Back
      </div>
    </div>
    <div class="login__container slide-pop-out-enter-active">
      <div class="login__header">
        <img
          class="login__logo"
          src="@/assets/static-assets/bottom-app-bar/baskyt@3x.png"
          alt="Baskyt Logo Image"
        />
        <img
          v-if="waiting"
          class="loader-spinner loader-spinner--sm"
          src="@/assets/static-assets/app-icons/spinner.gif"
          alt="Spinner"
        />
      </div>
      <h3 class="login__form__title">Business {{ region }}</h3>
      <template v-if="resetPassword === 'show'">
        <h2 class="mx-auto my-2 authHeading">Change Password</h2>
        <b-form @submit.prevent="resetPass" class="w-100 form login__form">
          <!-- <h3 class="login__form__title">Business</h3> -->
          <div
            class="login__form__errors"
            :class="
              message === ''
                ? 'login__form__errors--hide'
                : 'login__form__errors--show'
            "
          >
            {{ message || "Requesting..." }}
          </div>
          <div class="form-group login__form__group">
            <!-- <label for="email-input" class="login__form__label">Email</label> -->
            <b-input
              v-model="password"
              class="form-control login__form__input"
              type="password"
              name="password-input"
              placeholder="New Password"
              id="password-input"
              :disabled="waiting"
              required
            />
          </div>
          <div class="form-group login__form__group">
            <!-- <label for="email-input" class="login__form__label">Email</label> -->
            <b-input
              v-model="confirmPassword"
              class="form-control login__form__input"
              type="password"
              name="password-input"
              placeholder="Confirm New Password"
              id="password-input"
              :disabled="waiting"
              required
            />
          </div>
          <div
            class="d-flex align-items-center justify-content-center position-relative"
          >
            <input
              class="baskyt-btn"
              :class="waiting ? 'baskyt-btn--disabled' : ''"
              type="submit"
              value="Reset"
              :disabled="waiting"
            />
          </div>
        </b-form>
      </template>
      <template v-if="resetPassword === ''">
        <template
          v-if="verificationState !== 'completed' && forgotPassword === ''"
        >
          <b-form
            @submit.prevent="loginAction"
            class="w-100 form login__form"
            :class="verificationState === 'sent' ? 'login__form--hide' : ''"
          >
            <!-- <h3 class="login__form__title">Business</h3> -->
            <div
              class="login__form__errors"
              :class="
                error === ''
                  ? 'login__form__errors--hide'
                  : 'login__form__errors--show'
              "
            >
              {{ error || "Requesting..." }}
            </div>
            <div class="form-group login__form__group">
              <!-- <label for="email-input" class="login__form__label">Email</label> -->
              <b-input
                class="form-control login__form__input"
                v-model="phone"
                type="text"
                name="userid-input"
                :placeholder="`${region === 'PK' ? '+92' : '+1'}xxxxxxx`"
                id="email-input"
                required
                :disabled="waiting"
              />
            </div>
            <div class="form-group login__form__group">
              <!-- <label for="email-input" class="login__form__label">Email</label> -->
              <b-input
                v-model="password"
                class="form-control login__form__input"
                type="password"
                name="password-input"
                placeholder="Password"
                id="password-input"
                :disabled="waiting"
                required
              />
            </div>
            <div class="forgot mb-3">
              Forgot Password ?
              <a class="forgot__link" @click="gotoForgotPassword">Click Here</a>
            </div>
            <div
              class="d-flex align-items-center justify-content-center position-relative"
            >
              <input
                class="baskyt-btn"
                :class="waiting ? 'baskyt-btn--disabled' : ''"
                type="submit"
                value="Login"
                :disabled="waiting"
              />
            </div>
          </b-form>
        </template>
        <template v-if="verificationState === 'completed'">
          <b-form
            @submit.prevent="verifyCode"
            class="w-100 form login__form"
            :class="verificationState === 'sent' ? 'login__form--hide' : ''"
          >
            <div
              class="login__form__errors"
              :class="
                message === ''
                  ? 'login__form__errors--hide'
                  : 'login__form__errors--show'
              "
            >
              {{ message || "Requesting..." }}
            </div>
            <div class="login__form__heading login__form__heading--lg">
              We sent you a code
            </div>
            <div class="login__form__heading login__form__heading--sm">
              Enter it below to verify {{ phone }}
            </div>
            <div class="form-group login__form__group">
              <div class="row mt-2 mx-auto login__form__group__verify-pass">
                <div
                  v-for="(key, i) in verificationKeyFields"
                  :key="i"
                  class="col"
                >
                  <input
                    maxlength="1"
                    type="text"
                    class=" login__form__group__verify-pass--input verify-pass"
                    :key="i"
                    :data-length="key.length"
                    :data-index="i"
                    :ref="`input-${i}`"
                    v-model="key.value"
                    @input="handleVerificationInput($event)"
                    required
                    :disabled="waiting"
                  />
                </div>
              </div>
              <!-- <small
                class="mx-auto mt-3 login__form__errors login__form__errors--show login__form__errors--left"
                >Didn't receive sms?</small -->
            </div>
            <div
              class="d-flex align-items-center justify-content-center position-relative"
            >
              <input
                class="baskyt-btn"
                :class="waiting ? 'baskyt-btn--disabled' : ''"
                type="submit"
                value="Verify"
                :disabled="waiting"
              />
            </div>
          </b-form>
        </template>
        <template v-if="forgotPassword === 'yes'">
          <h2 class="mx-auto authHeading">Forgot Password</h2>
          <p class="mx-auto">
            Select which contact details should we use to reset your password
          </p>
          <div @click="selectContact('phone')" class="row p-4 forgotPassword">
            <div class="col-4"></div>
            <div class="col-8 forgotPassword__text">
              <div>Via SMS</div>
              <div>.......123</div>
            </div>
          </div>
        </template>
        <template
          v-if="forgotPassword === 'phone' && verificationState !== 'completed'"
        >
          <b-form
            @submit.prevent="loginAction"
            class="w-100 form login__form"
            :class="verificationState === 'sent' ? 'login__form--hide' : ''"
          >
            <!-- <h3 class="login__form__title">Business</h3> -->
            <div
              class="login__form__errors"
              :class="
                error === ''
                  ? 'login__form__errors--hide'
                  : 'login__form__errors--show'
              "
            >
              {{ error || "Requesting..." }}
            </div>
            <div class="form-group login__form__group">
              <!-- <label for="email-input" class="login__form__label">Email</label> -->
              <b-input
                class="form-control login__form__input"
                v-model="phone"
                type="text"
                name="userid-input"
                :placeholder="`${region === 'PK' ? '+92' : '+1'}xxxxxxx`"
                id="email-input"
                required
                :disabled="waiting"
              />
            </div>
            <div
              class="d-flex align-items-center justify-content-center position-relative"
            >
              <input
                class="baskyt-btn"
                :class="waiting ? 'baskyt-btn--disabled' : ''"
                type="submit"
                value="Continue"
                :disabled="waiting"
              />
            </div> </b-form
        ></template>
        <template
          v-if="forgotPassword === 'email' && verificationState !== 'completed'"
        >
          <b-form
            @submit.prevent="generateCode"
            class="w-100 form login__form"
            :class="verificationState === 'sent' ? 'login__form--hide' : ''"
          >
            <!-- <h3 class="login__form__title">Business</h3> -->
            <div
              class="login__form__errors"
              :class="
                error === ''
                  ? 'login__form__errors--hide'
                  : 'login__form__errors--show'
              "
            >
              {{ error || "Requesting..." }}
            </div>
            <div class="form-group login__form__group">
              <!-- <label for="email-input" class="login__form__label">Email</label> -->
              <b-input
                class="form-control login__form__input"
                v-model="email"
                type="email"
                placeholder="Your email here..."
                name="userid-input"
                id="email-input"
                required
                :disabled="waiting"
              />
            </div>
            <div
              class="d-flex align-items-center justify-content-center position-relative"
            >
              <input
                class="baskyt-btn"
                :class="waiting ? 'baskyt-btn--disabled' : ''"
                type="submit"
                value="Continue"
                :disabled="waiting"
              />
            </div>
          </b-form>
        </template>
      </template>
      <div class="app-version">
        <span>BaskytBusiness</span>{{ $store.getters.appVersion }}
      </div>
    </div>
    <template v-if="verificationState === 'complete'">
      <verification-modal @modalClosed="modalClosed" :phone="phone" />
    </template>
    <template v-if="resetPassword === 'done'">
      <SuccessModal @successModalClosed="successModalClosed"
    /></template>
  </div>
</template>
<script>
import { mapState } from "vuex";
import verificationCodeModal from "@/view/sub-pages/auth/verificationCodeModal";
import SuccessModal from "@/view/sub-pages/auth/successModal";
import {
  GET_VERFICATION_CODE,
  LOGIN,
  RESET_PASS
} from "@/core/services/store/auth.module";
import { GET_BRANCHES } from "@/core/services/store/orders.module";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}login-page`,
  components: {
    "verification-modal": verificationCodeModal,
    SuccessModal
    // resetPassword
  },
  data() {
    return {
      phone: "",
      password: "",
      waiting: false,
      verificationState: "waiting",
      verificationCode: "",
      forgotPassword: "",
      email: "",
      message: "",
      resetPassword: "",
      confirmPassword: "",
      verificationKeyFields: [
        { length: 1, value: "" },
        { length: 1, value: "" },
        { length: 1, value: "" },
        { length: 1, value: "" }
      ]
    };
  },
  methods: {
    async loginAction() {
      let data = {};
      if (this.forgotPassword === "phone")
        data = {
          phone: this.phone,
          password: "",
          login: false,
          pref: "sms"
        };
      else
        data = {
          phone: this.phone,
          password: this.password,
          login: true
        };
      this.waiting = true;
      try {
        const response = await this.$store.dispatch(GET_VERFICATION_CODE, data);
        if (response === true) {
          setTimeout(() => {
            this.verificationState = "complete";
          }, 1000);
        }
      } catch (error) {
        console.log("loginAction | r", error);
      }
      setTimeout(() => {
        this.waiting = false;
      }, 500);
    },
    handleVerificationInput(e) {
      // Grab input's value
      const { value } = e.target;
      // Grab data-index value
      const index = parseInt(e.target.dataset.index, 10);
      // Grab data-length value
      const maxlength = 1;
      if (this.verificationKeyFields[index].value.length > maxlength) {
        e.preventDefault();
        // this.verificationKeyFields[index].value = value.slice(0, 6);
        this.$refs[`input-${parseInt(index + 1, 10)}`][0].focus();
        return;
      }
      // Shift focus to next input field if max length reached
      if (value.length >= maxlength) {
        if (typeof this.verificationKeyFields[index + 1] === "undefined") {
          e.preventDefault();
          return;
        }
        this.$refs[`input-${parseInt(index + 1, 10)}`][0].focus();
        e.preventDefault();
      }
    },
    goBack() {
      if (this.forgotPassword === "yes") this.forgotPassword = "";
      if (this.forgotPassword === "phone" || this.forgotPassword === "email")
        this.forgotPassword = "yes";
      if (this.verificationState === "completed") {
        this.verificationState = "waiting";
        if (this.forgotPassword === "phone" || this.forgotPassword === "email")
          this.forgotPassword = "yes";
        else this.forgotPassword = "";
      }
      if (this.resetPassword === "show") {
        this.verificationState = "completed";
        this.resetPassword = "";
        this.forgotPassword = "phone";
      }
    },
    async resetPass() {
      if (this.password === this.confirmPassword) {
        const data = {
          userId: this.phone,
          verificationCode: parseInt(this.code, 10),
          newPassword: this.password
        };
        this.waiting = true;
        try {
          const response = await this.$store.dispatch(RESET_PASS, data);
          if (response === true) {
            this.verificationKeyFields = [
              { length: 1, value: "" },
              { length: 1, value: "" },
              { length: 1, value: "" },
              { length: 1, value: "" }
            ];
            this.message = "";
            this.resetPassword = "done";
          }
        } catch (error) {
          console.log("loginAction | r", error);
        }
        this.waiting = false;
      } else this.message = "Passsword did'nt match please try again";
    },
    selectContact(contact) {
      this.forgotPassword = contact;
    },
    gotoForgotPassword() {
      this.forgotPassword = "yes";
    },
    generateCode() {
      this.verificationState = "complete";
    },
    async verifyCode() {
      const { region: isUS } = this.$store.state.regions;
      let code = "";
      Object.keys(this.verificationKeyFields).forEach(key => {
        code += this.verificationKeyFields[key].value;
      });
      let data = {};
      if (this.forgotPassword === "phone")
        data = {
          phone: this.phone,
          password: this.password,
          verificationCode: code,
          region: isUS ? "US" : "PK"
        };
      else
        data = {
          phone: this.phone,
          password: this.password,
          verificationCode: code,
          region: isUS ? "US" : "PK"
        };
      this.waiting = true;
      if (this.forgotPassword === "phone") {
        if (code === this.code) {
          this.message = "";
          this.resetPassword = "show";
          this.verificationState = "";
          this.password = "";
        } else {
          this.message = "Code did'nt match please type again";
          this.verificationKeyFields = [
            { length: 1, value: "" },
            { length: 1, value: "" },
            { length: 1, value: "" },
            { length: 1, value: "" }
          ];
          this.waiting = false;
        }
      } else {
        try {
          const response = await this.$store.dispatch(LOGIN, data);
          if (response === true) {
            this.message = "Fetching branches.";
            await this.$store.dispatch(GET_BRANCHES);
            this.message = "";

            this.$router.push({ name: "Active Orders" });
          } else {
            this.message = "Code didn't match please type again";
            this.waiting = false;
            this.verificationKeyFields = [
              { length: 1, value: "" },
              { length: 1, value: "" },
              { length: 1, value: "" },
              { length: 1, value: "" }
            ];
          }
        } catch (error) {
          console.log("loginAction | r", error);
        }
      }
      setTimeout(() => {
        this.waiting = false;
      }, 500);
    },
    modalClosed() {
      setTimeout(() => {
        this.verificationState = "sent";
      }, 500);
      setTimeout(() => {
        this.verificationState = "completed";
      }, 800);
    },
    successModalClosed() {
      setTimeout(() => {
        this.verificationState = "waiting";
        this.resetPassword = "";
        this.forgotPassword = "";
      }, 500);
    }
  },
  computed: {
    ...mapState({
      error: state => (state.auth.errors.length === 0 ? "" : state.auth.errors),
      region: state => {
        if (state.regions.region === "") {
          return "";
        }
        return state.regions.region ? "US" : "PK";
      },
      code: state => state.auth.code
    })
  }
};
</script>
<style lang="scss" scoped></style>
