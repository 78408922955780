<template>
  <div>
    <modal class="sucess-modal" ref="modalRef" hideClose>
      <template v-slot:body>
        <div class="sucess-modal__body">
          <div class="text-center mb-2">
            <img
              class="img-fluid "
              width="50px"
              src="@/assets/static-assets/icons/check.png"
              alt="success"
            />
          </div>
          <div class="text-center">
            <h2 class="success-modal__body--heading">
              Password reset successfull
            </h2>
            <p class="success-modal__body--info">
              You have successfully reset your password please use your new
              password while logging in.
            </p>
          </div>
          <button
            class="baskyt-btn baskyt-btn--modal baskyt-btn--red"
            @click="hide"
          >
            Sign In
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import BaskytModal from "@/view/components/common/BaskytModal";

export default {
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}verfication-modal`,
  components: {
    modal: BaskytModal
  },
  mounted() {
    this.$refs.modalRef.show();
  },
  methods: {
    hide() {
      setTimeout(() => {
        this.$refs.modalRef.hide();
        this.$emit("successModalClosed");
      }, 250);
    }
  }
};
</script>

<style lang="scss" scoped></style>
