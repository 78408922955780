<template>
  <div class="verification-modal">
    <modal ref="modalRef" hideClose>
      <template v-slot:body>
        <div class="verification-modal__body">
          <small class="login__form__errors login__form__errors--show">
            {{ $store.getters.getVerificationCode }}
          </small>
          <div class="text-center">
            We'll text your verification code to {{ phone }}
          </div>
          <button class="baskyt-btn baskyt-btn--silver" @click="hide">
            OK
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import BaskytModal from "@/view/components/common/BaskytModal";

export default {
  props: {
    phone: String,
    codeType: String
  },
  name: `${process.env.VUE_APP_COMPONENTS_PREFIX}verfication-modal`,
  components: {
    modal: BaskytModal
  },
  mounted() {
    this.$refs.modalRef.show();
  },
  methods: {
    hide() {
      setTimeout(() => {
        this.$refs.modalRef.hide();
        this.$emit("modalClosed");
      }, 250);
    }
  }
};
</script>

<style lang="scss" scoped></style>
